import { Disclosure, Transition } from '@headlessui/react';
import { FieldMapper } from './SchemaFormMapper';
import {
  FormSchemaType,
  FormSchemaTypeOption
} from 'common/types/FormSchemaType';

import {
  ChevronDownIcon,
  ChevronUpIcon,
  PlusIcon,
  TrashIcon
} from '@heroicons/react/20/solid';
import { FieldArray, getIn, useFormikContext } from 'formik';
import { TfiAngleRight } from 'react-icons/tfi';
import Parser from 'html-react-parser';
import React from 'react';

interface SchemaFormElementProps {
  field: FormSchemaTypeOption;
  name: string;
  uploadPrefix?: string;
}
export const SchemaFormElement = (props: SchemaFormElementProps) => {
  const { field, name, uploadPrefix } = props;
  const {
    errors,
    touched,
    values,
    setFieldError,
    setFieldValue,
    validateField,
    setFieldTouched
  } = useFormikContext();
  if (!field) return null;

  if (field && field.type === 'Array')
    return (
      <SchemaFormArray
        label={field.label}
        name={name}
        max={field.max}
        uploadPrefix={uploadPrefix}
        {...field.additionalProps}
      />
    );
  if (
    field &&
    (field.type === 'Group' || field.type === 'NestedGroup') &&
    field.children
  ) {
    return (
      <Disclosure>
        {({ open }) => (
          <div className="border border-gray-200 p-2.5 rounded-md  col-span-2">
            <Disclosure.Button className="w-full text-left">
              {Parser(field.label)}
              <TfiAngleRight
                className={
                  open
                    ? 'rotate-90 transform float-right mt-1'
                    : 'float-right mt-1'
                }
              />
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel>
                <div className="p-2.5 space-y-6">
                  {Object.keys(field.children!).map(childKey => {
                    const fieldKey =
                      field.type === 'NestedGroup'
                        ? `${name}.${childKey}`
                        : childKey;
                    const child = field.children![childKey];
                    return (
                      <SchemaFormElement
                        key={fieldKey}
                        name={fieldKey}
                        field={child}
                        uploadPrefix={uploadPrefix}
                      />
                    );
                  })}
                </div>
              </Disclosure.Panel>
            </Transition>
          </div>
        )}
      </Disclosure>
    );
  }
  const FieldComponent = Object.prototype.hasOwnProperty.call(
    FieldMapper,
    field.type
  )
    ? FieldMapper[field.type]
    : null;
  if (!FieldComponent) return null;
  // @ts-ignore
  if (field.condition && !values[field.condition]) return null;
  const pathPrefix = uploadPrefix ? uploadPrefix : '';
  return (
    <div
      key={name}
      className={
        field && field.additionalProps && field.additionalProps.className
          ? field.additionalProps['className']
          : 'col-span-2'
      }
    >
      {React.createElement(FieldComponent, {
        value: getIn(values, name),
        // @ts-ignore
        hasError: !!getIn(errors, name),
        // @ts-ignore
        isDirty: getIn(touched, name),
        // @ts-ignore
        errorMessage: getIn(errors, name),
        name: name,
        label: Parser(field.label),
        required: field.required,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        validate: validateField,
        pathPrefix,
        ...field.additionalProps
      })}
    </div>
  );
};

interface SchemaFormArrayElementProps {
  max?: number;
  itemSchema?: FormSchemaType;
  label: string;
  name: string;
  uploadPrefix?: string;
}

export const SchemaFormArray = (props: SchemaFormArrayElementProps) => {
  const { itemSchema, name, label, max, uploadPrefix } = props;
  const { values } = useFormikContext();
  const newItem = Object.keys(itemSchema!).reduce(
    (o, key) => ({
      ...o,
      [key]: ''
    }),
    {}
  );

  //@ts-ignore
  const parts = name.split('.');
  let value: any = [];
  if (parts.length > 1) {
    value = values;
    // value = values[parts[0]][parts[1]] || [];
    for (const key of parts) {
      // Extract the property name and index (if applicable)
      //@ts-ignore
      const [propertyName, index] = key.match(/\w+/g);
      // Access the nested property
      value = value[propertyName] ?? [];
      // If an index is specified, access the specific element in the array
      if (index !== undefined) {
        value = value[parseInt(index, 10)] ?? [];
      }
    }
  } else {
    //@ts-ignore
    value = values[name] || [];
  }

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <div className="border border-gray-200 p-2.5 rounded-md col-span-2">
          <label>{label}</label>
          {(!max || value.length < max) && (
            <button
              type="button"
              onClick={() => arrayHelpers.push(newItem)}
              className="float-right leading-4 rounded-full bg-blue-600 p-1.5 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              <PlusIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          )}

          <ul role="list" className="divide-y divide-gray-100 mt-6 space-y-6">
            {value && value.length > 0
              ? value.map((item: any, index: number) => (
                  <Disclosure key={index} defaultOpen={true}>
                    {({ open }) => (
                      <li key={index} className="w-full">
                        <Disclosure.Button className="w-full text-left py-2.5">
                          Item {index + 1}
                          <TfiAngleRight
                            className={
                              open
                                ? 'rotate-90 transform float-right mt-1'
                                : 'float-right mt-1'
                            }
                          />
                        </Disclosure.Button>
                        <Transition
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel>
                            <div className="flex w-full justify-between gap-x-1.5 py-5">
                              <div className="w-[70%]">
                                {Object.keys(item).map(childKey => {
                                  const child = itemSchema![childKey];
                                  const fieldKey = `${name}[${index}].${childKey}`;
                                  return (
                                    <SchemaFormElement
                                      key={fieldKey}
                                      name={fieldKey}
                                      field={child}
                                      uploadPrefix={uploadPrefix}
                                    />
                                  );
                                })}
                              </div>
                              <div className="flex flex-end items-center align-right gap-x-2">
                                {index < value.length - 1 &&
                                  value.length > 1 && (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        arrayHelpers.move(index, index + 1)
                                      }
                                      className="leading-4 bg-gray-200 text-gray-600 p-1.5 hover:bg-gray-700 hover:text-gray-100"
                                    >
                                      <ChevronDownIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  )}
                                {index !== 0 && value.length > 1 && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.move(index, index - 1)
                                    }
                                    className="leading-4 bg-gray-200 text-gray-600 p-1.5 hover:bg-gray-700 hover:text-gray-100"
                                  >
                                    <ChevronUpIcon
                                      className="h-4 w-4"
                                      aria-hidden="true"
                                    />
                                  </button>
                                )}
                                <button
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                  className="rounded-full bg-red-600 p-1.5 text-white shadow-sm leading-4 hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                  <TrashIcon
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </Disclosure.Panel>
                        </Transition>
                      </li>
                    )}
                  </Disclosure>
                ))
              : null}
          </ul>
        </div>
      )}
    />
  );
};
