import { LoadingSpinner } from '../../components/LoadingSpinner';
import { TfiAngleDoubleRight, TfiAngleRight } from 'react-icons/tfi';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';

export interface OverrideProps {
  bgColor?: string;
  textColor?: string;
  borderColor?: string;
  hoverBgColor?: string;
  hoverTextColor?: string;
  hoverBorderColor?: string;
  arrowColor?: string;
}
interface ExpandingArrowButtonProps {
  className?: string;
  type?: 'button' | 'submit';
  showLoader?: boolean;
  styletype:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'teal-outlined'
    | 'blue-background';
  size: 'medium' | 'large';
  arrowtype: 'single' | 'double';
  label: string;
  overrides?: OverrideProps;
  onClick?: () => void;
}
interface StyledButtonProps {
  styletype:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'teal-outlined'
    | 'blue-background';
  overrides?: OverrideProps;
  size?: 'medium' | 'large';
}

const BaseArrow = styled.span`
  position: relative;
  font-size: 0.8em;
`;

const Arrow = tw(BaseArrow)`
${(props: StyledButtonProps) =>
  props.styletype === 'primary'
    ? 'text-white'
    : props.styletype === 'secondary'
    ? 'text-trhp-blue'
    : props.styletype === 'tertiary'
    ? 'text-black'
    : props.styletype === 'teal-outlined'
    ? 'text-trhp-teal'
    : 'text-trhp-teal'}
  opacity-0
  -ml-[1em]
  group-hover:opacity-100
  group-hover:ml-1
  transition-all
  ease
  duration-300
`;

const overrideArrowStyles = (overrides?: OverrideProps) => {
  if (!overrides) return {};
  if (overrides.arrowColor) {
    return { color: overrides.arrowColor };
  }
};
const overridesAndSizeToStyles = (
  overrides?: OverrideProps,
  size?: 'medium' | 'large'
) => {
  if (!overrides && !size) return {};
  const styles: any = {};
  if (size) {
    styles.paddingTop = size === 'medium' ? '0.3em' : '6px';
    styles.paddingBottom = size === 'medium' ? '0.3em' : '6px';
    styles.paddingRight = size === 'medium' ? '1em' : '20px';
    styles.paddingLeft = size === 'medium' ? '1em' : '20px';
    styles.fontSize = size === 'medium' ? '1rem' : '20px';
    styles.fontWeight = '400';
  }
  if (overrides?.bgColor) {
    styles.backgroundColor = overrides.bgColor;
  }
  if (overrides?.borderColor) {
    styles.borderColor = overrides.borderColor;
  }
  if (overrides?.textColor) {
    styles.color = overrides.textColor;
  }
  // if (overrides.hoverBgColor) {
  //   styles. = `hover:bg-${overrides.hoverBgColor}`;
  // }
  // if (overrides.hoverBorderColor) {
  //   styles. = `hover:border-${overrides.hoverBorderColor}`;
  // }
  // if (overrides.hoverTextColor) {
  //   styles. = `hover:text-${overrides.hoverTextColor}`);
  // }
  return styles;
};
const BaseStyledButton = styled.button`
  &:hover {
    ${(props: StyledButtonProps) =>
      props.overrides?.hoverBgColor
        ? 'background-color: ' + props.overrides?.hoverBgColor + ' !important;'
        : ''}
    ${(props: StyledButtonProps) =>
      props.overrides?.hoverBorderColor
        ? 'border-color: ' + props.overrides?.hoverBorderColor + ' !important;'
        : ''}
        ${(props: StyledButtonProps) =>
      props.overrides?.hoverTextColor
        ? 'color: ' + props.overrides?.hoverTextColor + ' !important;'
        : ''}
  }
`;
const StyledButton = tw(BaseStyledButton)`${(props: StyledButtonProps) =>
  props.styletype === 'primary'
    ? 'text-white border-trhp-teal bg-trhp-teal '
    : props.styletype === 'secondary'
    ? 'border-trhp-blue text-trhp-blue bg-white hover:bg-[#0000000d] hover:border-[#0000000d]'
    : props.styletype === 'tertiary'
    ? 'shadow-sm hover:bg-gray-50'
    : props.styletype === 'teal-outlined'
    ? 'border-trhp-teal text-trhp-teal bg-white hover:bg-white hover:border-trhp-text-light-gray'
    : 'text-white border-trhp-teal bg-trhp-blue hover:border-[#aaa]'}
  group
  uppercase
  relative
  border-2
  text-base
  inline-flex
  text-center
  items-center
  justify-center
  focus:outline-none
  focus:ring-offset-2
  font-medium
  focus:ring-2
  focus:ring-trhp-teal
  transition-all
  ease
  duration-300
  hover:pr-[0.7em]
`;
// .3em 2em .3em .7em
export const ExpandingArrowButton = (props: ExpandingArrowButtonProps) => {
  const {
    styletype,
    label,
    type,
    arrowtype,
    showLoader,
    overrides,
    className,
    onClick,
    size = 'medium'
  } = props;

  return (
    <StyledButton
      type={type ? type : 'button'}
      styletype={styletype}
      onClick={onClick}
      style={overridesAndSizeToStyles(overrides, size)}
      overrides={overrides}
      className={className}
    >
      {!showLoader ? (
        <>
          {label}
          <Arrow
            styletype={styletype}
            overrides={overrides}
            style={overrideArrowStyles(overrides)}
          >
            {arrowtype === 'double' ? (
              <TfiAngleDoubleRight className="align-middle" />
            ) : (
              <TfiAngleRight className="align-middle" />
            )}
          </Arrow>
        </>
      ) : (
        <LoadingSpinner variant={styletype} />
      )}
    </StyledButton>
  );
};
