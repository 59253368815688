import { LoadingSpinner } from '../LoadingSpinner';
import { ReactNode } from 'react';
import Link from 'next/link';
import joinCssClasses from '../../utils/joinCssClasses';

export interface ButtonProps {
  children?: ReactNode;
  className?: string;
  href?: string;
  hrefNewTab?: boolean;
  onClick?: () => void;
  openInNewTab?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  type?: 'button' | 'submit' | 'reset';
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'default'
    | 'link'
    | 'outline'
    | 'accept';
  disabled?: boolean;
  loading?: boolean;
}

//TODO: create a function to test for external links and have an option for an anchor styles as a button

const Button = (props: ButtonProps) => {
  const {
    children,
    className = '',
    href = '',
    hrefNewTab = false,
    onClick,
    size = 'md',
    type = 'button',
    variant = 'default',
    loading,
    disabled
  } = props;
  const buttonVariants = {
    default: 'border-gray-300 bg-white hover:bg-gray-50 focus:ring-gray-50',
    primary:
      'bg-blue-500 text-white hover:bg-blue-900 focus:ring-blue border-2 border-blue hover:border-slate-blue',
    secondary:
      'bg-none text-blue underline border-none shadow-none hover:no-underline',
    tertiary:
      'bg-none border-2 border-red-500 text-red-500 hover:bg-red-700 hover:text-white focus:ring-red-700',
    link: 'bg-none text-black-500 hover:text-green border-none shadow-none',
    outline:
      'bg-none border-4 border-blue text-blue focus:ring-blue hover:bg-blue hover:text-white',
    accept:
      'bg-green text-white hover:bg-res-green focus:ring-blue border-2 border-green hover:border-res-green'
  };
  const disabledClasses =
    'bg-gray-200 text-gray-500 hover:bg-gray-200 cursor-not-allowed';

  const buttonSizes = {
    xs: 'px-2.5 py-1.5 text-xs',
    sm: 'px-3 py-2 text-sm',
    md: 'px-4 py-2 text-sm',
    lg: 'px-4 py-2 text-base',
    xl: 'px-6 py-3 text-base'
  };
  const CssClasses = joinCssClasses(
    `text-center border font-medium shadow-none focus:outline-none focus:ring-2 focus:ring-offset-2`,
    disabled ? disabledClasses : buttonVariants[variant],
    buttonSizes[size],
    className
  );

  return (
    <>
      {href ? (
        <Link
          className={CssClasses}
          href={href}
          target={hrefNewTab ? '_blank' : '_self'}
          rel={hrefNewTab ? 'noopener noreferrer' : undefined}
        >
          {children}
        </Link>
      ) : (
        <button
          type={type}
          className={CssClasses}
          onClick={onClick}
          disabled={disabled}
        >
          {loading && variant !== 'link' ? (
            <LoadingSpinner size={size} variant={variant} />
          ) : (
            <>{children}</>
          )}
        </button>
      )}
    </>
  );
};

export default Button;
