//@ts-nocheck
import { AutoCompleteDropdownProps } from './AutoCompleteDropdown';
import { CheckboxProps } from './Checkbox';
import { ColorPickerProps } from './ColorPicker';
import { DatePickerProps } from './DatePicker';
import { Field } from 'formik';
import { FileUploadProps } from './FileUpload';
import { RadioGroupProps } from './RadioGroup';
import { SelectProps } from './Select';
import { TagFieldProps } from './TagField';
import { TextAreaProps } from './TextArea';
import { TextFieldProps } from './TextField';
import { ToggleProps } from './Toggle';
import { WysiwygFieldProps } from './Wysiwyg';
import React from 'react';
import dynamic from 'next/dynamic';

//this will be a discriminated union type of available form field props
type AvailableFieldProps =
  | TextFieldProps
  | SelectProps
  | RadioGroupProps
  | DatePickerProps
  | AutoCompleteDropdownProps
  | TagFieldProps
  | FileUploadProps
  | ToggleProps
  | TextAreaProps
  | ColorPickerProps
  | WysiwygFieldProps
  | CheckboxProps;

const HiddenField = () => {
  return <Field as="input" type="hidden" />;
};

const LabelField = (props: AvailableFieldProps) => {
  return <label htmlFor={props.additionalProps?.for}>{props.label}</label>;
};

export const FieldMapper: {
  [key: string]: React.ComponentType<AvailableFieldProps>;
} = {
  TextField: dynamic(() => import('./TextField')),
  Password: dynamic(() => import('./Password')),
  TextArea: dynamic(() => import('./TextArea')),
  Select: dynamic(() => import('./Select')),
  Autocomplete: dynamic(() => import('./AutoCompleteDropdown')),
  RadioGroup: dynamic(() => import('./RadioGroup')),
  DatePicker: dynamic(() => import('./DatePicker')),
  Tag: dynamic(() => import('./TagField')),
  FileUpload: dynamic(() => import('./FileUpload')),
  Toggle: dynamic(() => import('./Toggle')),
  ColorPicker: dynamic(() => import('./ColorPicker')),
  RichTextEditor: dynamic(() => import('./Wysiwyg')),
  Hidden: HiddenField,
  Label: LabelField,
  Checkbox: dynamic(() => import('./Checkbox'))
};
