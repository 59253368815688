import {
  ExpandingArrowButton,
  OverrideProps
} from 'components/button/ExpandingArrowButton';
import { useFormikContext } from 'formik';
import Button from '../button/Button';
import Link from 'next/link';
interface FormActionProps {
  cancelPath?: string;
  saveText?: string;
  secondSubmitOption?: {
    variant: 'default' | 'primary' | 'secondary' | 'accept';
    label: string;
    callback?: () => void;
  };
  saveCallback?: () => void;
  cancelOnClick?: (e: any) => void;
  buttonOverrides?: OverrideProps;
}
export const FormActions = ({
  cancelPath,
  saveText,
  secondSubmitOption,
  buttonOverrides,
  saveCallback,
  cancelOnClick
}: FormActionProps) => {
  const formikProps = useFormikContext();
  return (
    <div className="pt-5 border-t border-gray-300 col-span-2">
      <div className="flex justify-end">
        {cancelPath && (
          <Link
            href={cancelPath}
            onClick={cancelOnClick}
            className="rounded-md border border-gray-400 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-ros-blue focus:ring-offset-2 mr-4"
          >
            Cancel
          </Link>
        )}
        {secondSubmitOption && (
          <Button
            type="submit"
            disabled={!formikProps.isValid}
            variant={secondSubmitOption.variant}
            loading={formikProps?.isSubmitting}
            onClick={secondSubmitOption.callback}
            className="mr-4"
          >
            {secondSubmitOption.label}
          </Button>
        )}
        {buttonOverrides ? (
          <ExpandingArrowButton
            arrowtype="single"
            styletype="primary"
            type="submit"
            size="medium"
            overrides={buttonOverrides}
            onClick={saveCallback ? saveCallback : undefined}
            label={saveText ? saveText : 'Save'}
          />
        ) : (
          <Button
            type="submit"
            variant="primary"
            loading={formikProps?.isSubmitting}
            onClick={saveCallback ? saveCallback : undefined}
          >
            {saveText ? saveText : 'Save'}
          </Button>
        )}
      </div>
      <div className="flex justify-end">
        {!formikProps.isValid && formikProps?.dirty && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            Please check the form for errors.
          </p>
        )}
      </div>
    </div>
  );
};
